import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import useMediaQuery from "../Hooks/MediaQuery";
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Typography } from "@mui/material";
import { BackImgHome } from "../Pages/HomeMain/HomeStyles";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FB from '../Assets/Images/facebook.png';

const ButtonStyle = {
    color: '#fff',
    borderRadius: 35,
    padding: '10px 20px',
    marginTop: '2%',
    backgroundColor: '#F7B324',
    textDecoration: 'none',
    textTransform: 'none',
    height: '38px',
    fontFamily: 'SweetSansProThinItalic',
    boxShadow: 'none',
    justifyContent: 'space-between',

};
function MasonryLayout({ data, isLoading }) {
    const isMobile = useMediaQuery('(min-width: 950px)');
    const [visibleCount, setVisibleCount] = React.useState(8);
    function truncateText(text, maxLength) {
        return text?.length && text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
    }

    function formatDate(isoString) {
        const date = new Date(isoString);
        const dateOptions = { day: 'numeric', month: 'long', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-GB', dateOptions);
        const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
        const formattedTime = date.toLocaleTimeString('en-GB', timeOptions);
        return `${formattedDate} om ${formattedTime}`;
    }
    return (
        <div style={{backgroundColor:'aqua'}}>
            <ResponsiveMasonry gutter="15px" margin="10px"
                columnsCountBreakPoints={{ 300: 2, 500: 3, 700: 4, 900: 5 }}

            >
                <Masonry gutter="15px">
                    {data
                        .slice(0, visibleCount)
                        .map((data, index) => (
                            <React.Fragment key={index}>
                                <Card
                                    sx={{
                                        display: 'block',
                                        marginBottom: '0%',
                                        borderRadius: '5px',
                                        position: 'relative',
                                        cursor: 'pointer',
                                        overflow: 'hidden',
                                        backgroundColor: '#F6F6F6',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0)',
                                        '&:hover .overlay, &:hover .cardActions': {
                                            opacity: 1,
                                            visibility: 'visible',


                                        },
                                        '&:hover .overlay': {
                                            backgroundColor: '#2A0800',
                                            opacity: '0.60'
                                        },
                                        '@media (max-width: 950px)': {
                                            flex: '1 0 auto',
                                            maxWidth: '100%'
                                        }
                                    }}
                                    elevation={1}
                                >
                                    <Box className="overlay" sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        opacity: 0,
                                        visibility: 'hidden',
                                        transition: 'opacity 0.3s, visibility 0s linear 0.3s',
                                        zIndex: 2,
                                    }} />
                                    <CardHeader
                                        avatar={
                                            <BackImgHome style={{
                                                marginLeft: '-10%'
                                            }}>
                                                <Avatar sx={{ width: isMobile ? "90%" : 20, height: isMobile ? "90%" : 20, objectFit: 'cover' }} src={data.latestPost && data.latestPost[0].user?.profilePic} alt="Avatar" />
                                            </BackImgHome>
                                        }
                                        title={
                                            <Typography sx={{
                                                fontWeight: 'bold',
                                                fontSize: isMobile ? '13px' : '11px',
                                                fontFamily: 'SweetSansBold',
                                                color: '#2A0800',
                                                maxWidth: '30ch', // Example: truncate text after 25 characters
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                marginLeft: isMobile ? '-4%' : '-12%'
                                            }}>
                                                {truncateText(data.latestPost && data.latestPost[0].user?.name, isMobile ? 30 : 25)}
                                            </Typography>
                                        }
                                        subheader={
                                            <Typography sx={{
                                                color: '#2A0800',
                                                fontSize: isMobile ? '8px' : '6px',
                                                fontFamily: 'SweetSansRegular',
                                                marginLeft: isMobile ? '-4%' : '-12%'

                                            }}>
                                                {formatDate(data.latestPost && data.latestPost[0].time)}
                                            </Typography>
                                        }
                                        action={
                                            <Avatar
                                                sx={{ width: 15, height: 15, objectFit: 'cover', marginTop: '-8px' }}
                                                src={FB}
                                                alt="Right Side Image"
                                            />
                                        }
                                        sx={{
                                            backgroundColor: '#F6F6F6',
                                            height: '25px',
                                            padding: '14px',
                                            marginTop: '5px'
                                        }}
                                    />
                                    {/* <CardContent sx={{
                                        minHeight: 'auto',
                                    }}>
                                        <Typography fontSize={'12px'} color="#2A0800" fontWeight={'300'} fontFamily={'Segoeui'}
                                            style={{
                                                maxWidth: '55ch', // Example: truncate text after 25 characters
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'pre-line',
                                            }}
                                        >
                                            {data.latestPost[0] && data.latestPost[0]?.text}
                                        </Typography>
                                    </CardContent> */}
                                    <CardContent sx={{ minHeight: 'auto' }}>
                                        <Typography
                                            fontSize={'12px'}
                                            color="#2A0800"
                                            fontWeight={'300'}
                                            fontFamily={'Segoeui'}
                                            style={{
                                                maxWidth: '55ch', // Example: truncate text after 55 characters
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'pre-line', // This allows \n to be converted to line breaks
                                            }}
                                        >
                                            {data.latestPost[0]?.content.split(/(#[^\s]+)/g).map((part, index) =>
                                                part.startsWith('#') ? (
                                                    <span key={index} style={{ color: '#0064D1' }}>{part}</span>
                                                ) : (
                                                    part
                                                )
                                            )}
                                        </Typography>
                                    </CardContent>

                                    {/* <CardMedia
                                        component="img"
                                        image={
                                            (data.latestPost && data.latestPost[0] && (
                                                data.latestPost[0].sharedPost && data.latestPost[0].sharedPost.media && data.latestPost[0].sharedPost.media.length > 0
                                                    ? (data.latestPost[0].sharedPost.media[0].thumbnail || data.latestPost[0].sharedPost.media[1]?.thumbnail)
                                                    :
                                                    data.latestPost[0].media && data.latestPost[0].media.length > 0 &&
                                                        data.latestPost[0].media[0].two_photos_subattachments &&
                                                        data.latestPost[0].media[0].two_photos_subattachments.nodes &&
                                                        data.latestPost[0].media[0].two_photos_subattachments.nodes.length > 0
                                                        ? data.latestPost[0].media[0].two_photos_subattachments.nodes[0].media.image.uri
                                                        :
                                                        data.latestPost[0].media && data.latestPost[0].media[0].header_photos_subattachments &&
                                                            data.latestPost[0].media[0].header_photos_subattachments.nodes &&
                                                            data.latestPost[0].media[0].header_photos_subattachments.nodes.length > 0
                                                            ? data.latestPost[0].media[0].header_photos_subattachments.nodes[0].media.image.uri
                                                            : data.latestPost[0].media && data.latestPost[0].media.length > 0
                                                                ? (data.latestPost[0].media[0].thumbnail || data.latestPost[0].media[1]?.thumbnail)

                                                                : <></>
                                            ))  
                                        }
                                        style={{
                                            objectFit: isMobile ? 'cover' : 'contain',
                                            width: isMobile ? '90%' : '90%',
                                            margin: 'auto',
                                            borderRadius: '5px',
                                            marginBottom: '12px'
                                        }}
                                    /> */}

                                    {
                                        (data.latestPost && data.latestPost[0] && (
                                            (data.latestPost[0].sharedPost && data.latestPost[0].sharedPost.media && data.latestPost[0].sharedPost.media.length > 0 &&
                                                (data.latestPost[0].sharedPost.media[0].thumbnail || data.latestPost[0].sharedPost.media[1]?.thumbnail)
                                            ) ||
                                            (data.latestPost[0].media && data.latestPost[0].media.length > 0 &&
                                                (data.latestPost[0].media[0].two_photos_subattachments?.nodes?.length > 0
                                                    ? data.latestPost[0].media[0].two_photos_subattachments.nodes[0].media.image.uri
                                                    : data.latestPost[0].media[0].header_photos_subattachments?.nodes?.length > 0
                                                        ? data.latestPost[0].media[0].header_photos_subattachments.nodes[0].media.image.uri
                                                        : data.latestPost[0].media[0].thumbnail || data.latestPost[0].media[1]?.thumbnail
                                                )
                                            )
                                        )) ? (
                                            <CardMedia
                                                component="img"
                                                image={
                                                    (data.latestPost[0].sharedPost && data.latestPost[0].sharedPost.media && data.latestPost[0].sharedPost.media.length > 0
                                                        ? (data.latestPost[0].sharedPost.media[0].thumbnail || data.latestPost[0].sharedPost.media[1]?.thumbnail)
                                                        : data.latestPost[0].media && data.latestPost[0].media.length > 0 &&
                                                            data.latestPost[0].media[0].two_photos_subattachments?.nodes?.length > 0
                                                            ? data.latestPost[0].media[0].two_photos_subattachments.nodes[0].media.image.uri
                                                            : data.latestPost[0].media && data.latestPost[0].media[0].header_photos_subattachments?.nodes?.length > 0
                                                                ? data.latestPost[0].media[0].header_photos_subattachments.nodes[0].media.image.uri
                                                                : data.latestPost[0].media[0].thumbnail || data.latestPost[0].media[1]?.thumbnail
                                                    )
                                                }
                                                onError={(e) => { e.target.style.display = 'none'; }} // Hide image if load fails
                                                style={{
                                                    objectFit: isMobile ? 'cover' : 'contain',
                                                    width: isMobile ? '90%' : '90%',
                                                    margin: 'auto',
                                                    borderRadius: '5px',
                                                    marginBottom: '12px'
                                                }}
                                            />
                                        ) : null
                                    }



                                    {/* <CardActions
                                        className="cardActions"
                                        disableSpacing
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between', 
                                            alignItems: 'center',
                                            position: 'absolute',
                                            bottom: 10,
                                            left: 0,
                                            width: '90%',
                                            zIndex: 3,
                                            visibility: 'hidden',
                                            opacity: 1, 
                                            transition: 'opacity 0.3s, visibility 0s linear 0.3s',
                                            alignSelf: 'center'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                backgroundColor: '#F7B324', 
                                                width: '35px',
                                                height: '35px',
                                                borderRadius: '50%', 
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginLeft: '4%'
                                            }}
                                            onClick={handleStarClick} 

                                        >
                                            <StarImage src={isStarFilled ? FilledStar : NotFilledStar} alt="Star" />
                                        </Box>
                                        <BackLinkCard as="a" href={data.latestPost && data.latestPost[0].user?.profileUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} >
                                            <TextLinkCard>Bekijk bericht</TextLinkCard>
                                            <ArrowForwardIcon style={{ fontSize: '14px', marginLeft: '5px', marginBottom: '-3%' }} />
                                            <UnderlineCard></UnderlineCard>
                                        </BackLinkCard>
                                    </CardActions> */}
                                    <CardActions
                                        className="cardActions"
                                        disableSpacing
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            position: 'absolute', // Change to relative
                                            bottom: 'auto', // Remove bottom positioning
                                            top: '50%', // Center vertically
                                            transform: 'translateY(-50%)', // Adjust for true center
                                            width: '100%',
                                            zIndex: 3,
                                            visibility: 'hidden', // Make sure it's visible
                                            padding: '10px',
                                            opacity: 1,
                                            transition: 'opacity 0.3s, visibility 0s linear 0.3s',
                                        }}
                                    >
                                        <a href={data.latestPost && data.latestPost[0].user?.profileUrl} target="_blank" rel="noopener noreferrer" >
                                            <Button
                                                variant="contained"
                                                endIcon={<ArrowForwardIcon />}
                                                style={{
                                                    ...ButtonStyle, width: isMobile ? '100%' : '100%',
                                                    fontSize: isMobile ? '13px' : '9px',
                                                    fontWeight: '300'
                                                }}
                                            >
                                                Bekijk bericht
                                            </Button>
                                        </a>
                                    </CardActions>
                                </Card>
                                {/* {!isMobile &&
                                    <IconButton sx={{ float: 'right', marginLeft: 'auto', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                        <CardMenu />
                                    </IconButton>
                                } */}
                            </React.Fragment>
                        ))}
                </Masonry>
            </ResponsiveMasonry>
        </div>
    );
}
export default MasonryLayout